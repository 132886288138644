<template>
  <div class="player-admin">
    <h4 class="pl-2 font-weight-bold">ユーザ情報管理</h4>
    <!-- Filter Search -->
    <div class="top-event d-flex flex-column flex-md-column">
      <b-button
        class="d-block d-md-none mx-0 btn-toggle mb-2 mx-md-2"
        v-b-toggle.event-filter
        >絞り込む</b-button
      >
      <b-collapse id="event-filter" class="w-100 d-md-block">
        <b-row class="filter-search justify-content-end">
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="fromDate"
                type="text"
                placeholder="開始期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="fromDate"
                  locale="ja"
                  @input="fromDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-input-group class="mb-1 mb-md-3">
              <b-form-input
                v-model="toDate"
                type="text"
                placeholder="終了期間"
                class="text-truncate"
                disabled
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  :hide-header="true"
                  label-help=""
                  v-model="toDate"
                  locale="ja"
                  @input="toDateSelected"
                  button-only
                  right
                >
                  <template #button-content>
                    <esports-calendar-date />
                  </template>
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <b-form-input
              class="name-event mb-2 mb-md-3 text-truncate"
              v-model="filterAll"
              debounce="500"
              placeholder="フリーワード"
            ></b-form-input>
          </b-col>
          <b-col cols="12" sm="6" md="3" lg="3">
            <div class="d-flex">
              <b-button
                class="primary mb-4 mb-md-3 mr-2"
                @click="handleFilterEvents"
                block
                >検索</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <!-- Main table element -->
    <b-table
      :items="filtered"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filterAll"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :show-empty="hideBeforeLoading"
      small
      stacked="md"
      @filtered="onFiltered"
      responsive="sm"
      bordered
      ref="table-data"
    >
      <template #top-row="row">
        <b-th v-for="(col, index) in row.fields" :key="index">
          <b-form-input
            v-model="filters[col.key]"
            v-if="!['id'].includes(col.key)"
            type="search"
            :placeholder="col.label"
            debounce="500"
          ></b-form-input>
        </b-th>
      </template>

      <template #cell(username)="row">
        <h5 v-if="row.item.username">
          <b-badge variant="success">
            {{ row.item.username }}
          </b-badge>
        </h5>
      </template>
      <template #cell(twitterUsername)="row">
        <h5 v-if="row.item.twitterUsername">
          <b-badge variant="warning">
            {{ row.item.twitterUsername }}
          </b-badge>
        </h5>
      </template>

      <template #cell(id)="row">
        <span
          role="button"
          class="mx-2 my-1 d-inline-block position-relative"
          @click="handleDetail(row)"
          ><b-badge variant="info" class="text-white py-2">{{
            row.detailsShowing ? "詳細非表示" : "詳細表示"
          }}</b-badge>
          <esports-loading-button
            v-if="row.item.isLoading"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
          />
        </span>
      </template>

      <template #row-details="row">
        <b-card
          no-body
          class="overflow-hidden animate__animated"
          :class="[row.detailsShowing ? 'animate__zoomOut' : 'animate__zoomIn']"
        >
          <b-row no-gutters>
            <b-col md="3" lg="4">
              <template v-if="row.item.content && row.item.content.picture">
                <b-card-img-lazy
                  v-if="row.item.content.picture.includes('/images/avatar')"
                  :src="row.item.content.picture"
                  alt="Image"
                  class="rounded-0"
                  v-bind="mainProps('#fff')"
                ></b-card-img-lazy>
                <b-avatar
                  v-else
                  rounded="sm"
                  :src="row.item.content.picture"
                  size="30rem"
                ></b-avatar>
                <label class="mt-1 ml-1" v-if="row.item.content">{{
                  row.item.content.labelFileName
                }}</label>
              </template>
              <template v-else>
                <b-avatar
                  rounded="sm"
                  :text="
                    row.item.username ? letterName(row.item.username) : 'DE'
                  "
                  size="30rem"
                ></b-avatar>
              </template>
            </b-col>
            <b-col md="7" lg="7">
              <b-card-body>
                <h5>{{ row.item.username || "" }}</h5>
                <b-card-text>
                  <h5>
                    <b-badge
                      class="mx-1"
                      v-for="team in row.item.teams"
                      :key="team.teamId"
                      variant="warning"
                      >{{ team.title }}</b-badge
                    >
                  </h5>
                </b-card-text>
              </b-card-body>
              <ul>
                <!-- <li><b>Player ID</b>: {{ row.item.playerId }}</li> -->
                <li><b>登録日</b>: {{ row.item.signUpDate }}</li>
                <template v-if="row.item.content">
                  <li>
                    <b>投票チケット</b>:
                    <b-badge variant="warning">
                      {{ formatPoint(row.item.content.currentTickets)
                    }}<span class="text">票</span>
                    </b-badge>
                  </li>
                  <li>
                    <b>獲得ドネト</b>: 
                    <b-badge variant="warning">
                      {{ formatPoint(row.item.content.currentPoints)
                    }}<span class="text">pt</span>
                    </b-badge>
                  </li>
                  <li>
                    <b>メールアドレス</b>: 
                    {{ row.item.content.email }}
                  </li>
                  <li>
                    <b>Twitterユーザ名</b>:
                    {{
                      row.item.content.twitterUsername
                        ? "@" + row.item.content.twitterUsername
                        : ""
                    }}
                  </li>
                </template>
              </ul>
            </b-col>
          </b-row>
        </b-card>
      </template>

      <template #empty>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
      <template #emptyfiltered>
        <div role="alert" aria-live="polite">
          <div class="text-center my-2 not-result">
            該当データが存在しません。
            <span role="button" @click="resetFilter" class="ext-reset"
              >リセット</span
            >
          </div>
        </div>
      </template>
    </b-table>

    <esports-paging
      :items="items"
      :limit="limit"
      :totalRows="totalRows"
      :isResult="isResult"
    >
      <template v-slot:perpage>
        <b-form-select
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
        ></b-form-select>
      </template>
      <template v-slot:paging>
        <template v-if="isResult && totalRows > perPage">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0 ml-3"
          ></b-pagination>
        </template>
      </template>
    </esports-paging>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dismissCount } from "@/mixins";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

export default {
  name: "UserList",
  data() {
    return {
      isSubmitted: false,
      fromDate: "",
      toDate: "",
      playerId: "",
      items: [],
      fields: [
        {
          key: "signUpDate",
          label: "登録日",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "username",
          label: "ユーザー名",
          sortDirection: "desc",
          sortable: true,
          class: "w-30",
        },
        {
          key: "email",
          label: "メールアドレス",
          sortDirection: "desc",
          sortable: true,
        },
        {
          key: "twitterUsername",
          label: "Twitterユーザ名",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "id", label: "" },
      ],
      filters: {
        email: "",
        username: "",
        signUpDate: "",
        twitterUsername: "",
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 15, 20, { value: 100, text: "すべて" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterAll: null,
      filterOn: ["email", "username", "signUpDate", "twitterUsername"],
      isEnableCloseModal: false,
      limit: 0,
      isDetailRow: false,
    };
  },
  mixins: [validationMixin, dismissCount],
  watch: {
    perPage() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    currentPage(page) {
      this.$nextTick(() => {
        let currentItems = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.limit = this.perPage * (page - 1) + currentItems;
      });
    },
    filtered(nVal) {
      this.$nextTick(() => {
        this.currentPage = this.isDetailRow ? this.currentPage : 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
        this.totalRows = nVal.length;
      });
    },
  },
  computed: {
    isResult() {
      return this.items.length;
    },
    filtered() {
      let filtered = this.items.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key])
            ?.toLowerCase()
            .includes(this.filters[key]?.toLowerCase())
        );
      });
      
      return filtered.length > 0 ? filtered : [];
    },
  },
  async mounted() {
    this.setLazyLoading(true);
    await Promise.all([this.getUsersAll()]);
    this.hideBeforeLoading = true;

    if (this.items.length > this.perPage) {
      this.limit = this.perPage;
    } else {
      this.limit = this.items.length;
    }
  },
  methods: {
    async getUsersAll(params) {
      const result = await this.$store
        .dispatch("user/getUsersAll", params)
        .catch((err) => {
          this.setLazyLoading(false);
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.items = result.map((player, index) => {
          player.picture = player.picture
            ? `${process.env.VUE_APP_BACKEND + player.picture}`
            : "";

          if (player.signUpDate) {
            player.signUpDate = moment(player.signUpDate).format("YYYY-MM-DD");
          }

          player.twitterUsername = player.twitterUsername
            ? `@${player.twitterUsername}`
            : "";

          player.isLoading = false;

          player.isDisabledCheckbox = false;

          return player;
        });

        this.totalRows = this.items.length;

        this.setLazyLoading(false);
      }
    },
    async handleFilterEvents() {
      let params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };

      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;

      await this.getUsersAll(params);
    },
    async resetFilter() {
      this.filterAll = "";
      this.filters.email = "";
      this.filters.username = "";
      this.filters.signUpDate = "";
      this.fromDate = null;
      this.toDate = null;
      await this.handleFilterEvents();
    },
    fromDateSelected(date) {
      if (!this.toDate || moment(date).isSameOrAfter(this.toDate, "day")) {
        this.toDate = date;
      }
    },
    toDateSelected(date) {
      if (!this.fromDate || !moment(date).isSameOrAfter(this.fromDate, "day")) {
        this.fromDate = date;
      }
    },
    onFiltered() {
      this.$nextTick(() => {
        this.currentPage = 1;
        this.limit = this.$refs["table-data"]?.getTbodyTrs().length ?? 0;
      });
    },
    async handleDetail(row) {
      row.item.isLoading = true;
      this.isDetailRow = true;

      let currentIdx = this.items.findIndex(
        (game) => game.userId === row.item.userId
      );

      if (row.detailsShowing) {
        row.item.isLoading = false;
        this.isDetailRow = false;
        row.toggleDetails();
        return;
      }

      const result = await this.$store
        .dispatch("user/getDetailUser", row.item.userId)
        .catch((err) => {
          console.log(err);
        });

      if (result) {
        let content = result;
        content.picture = content.avatar
          ? `${process.env.VUE_APP_BACKEND + content.avatar}`
          : "";

        if (result.avatar && !result.avatar.includes('/images/avatar')) {
          content.picture = result.avatar;
        }

        content.labelFileName = content.picture
          ? this.getNamePicture(content.picture)
          : "";

        if (currentIdx > -1) {
          this.items[currentIdx].content = content;
        }

        row.toggleDetails();
        row.item.isLoading = false;
      }
    },
  },
};
</script>